@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.App {
  font-family: 'Lato', sans-serif;
  top: 0;
  bottom: 0; 
  margin: 0 0 0 0;
  background-color: white;
}


