.styledlink-wrapper{
    display: grid;
    grid-template-columns: auto auto; 
    a{
        grid-column: 1; 
        color: black; 
        text-decoration: none;   
        transition: 1s linear;
    }
    a:hover{
        transition: 1s linear; 
        
    
    }
    img{
        transform: translate(-5px, 0); 
        visibility: hidden; 
        opacity: 0; 
        grid-column: 2; 
        align-self: center;
        transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

}
.styledlink-wrapper:hover{
    img{
        transform: translate(3px, 0); 
        visibility: visible; 
        opacity: 1; 
        transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
}

