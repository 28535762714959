$breakpoint-mobile: 900px; 

.contact-wrapper{
    display: grid;
    height: fit-content; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 30vh 1fr; 
    padding-right: 5vw; 
    padding-left: 5vw; 
    padding-bottom: 100px; 

    .contact-header{
        h1{
            transform: translate(0, -12px); 
            margin: 0px; 
        }
        grid-row: 2; 
        padding-left: 80px; 
        font-weight: 900; 
        font-size: 25px;
        grid-column: 1; 
        margin-top: 0; 
    }

    .contact-info-wrapper{
        grid-row: 2; 
        grid-column: 2; 

        display: grid; 
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(6, fit-content(200px));
        gap: 20px; 
        grid-template-areas: "firstname lastname"
        "purpose purpose"
        "email email"
        "message message"
        "none submit"; 

        .contact-firstname{
            grid-area: firstname;
        }
        .contact-lastname{
            grid-area: lastname;
        }
        .contact-purpose{
            grid-area: purpose;
        }
        .contact-email{
            grid-area: email;
        }
        .contact-message{
            grid-area: message;
        }
        .contact-submit{
            grid-area: submit; 
            p{
                float: right; 
                margin-top: 30px; 
                border: 1px solid black; 
                font-size: 18px; 
                font-weight: 400;
                padding: 20px 35px 20px 35px; 
                transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
            p:hover{
                transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
                background: black; 
                color: white; 
                border-color: white; 
                cursor: pointer; 
            }
        }
        
    }

    @media( max-width: $breakpoint-mobile){
        

    }


    
}

@media (max-width: $breakpoint-mobile){
    .contact-wrapper{
        height: auto; 
        grid-template-rows: 30vh auto auto; 
        .contact-header{
            padding-left: 0; 
            grid-column-start: 1; 
            grid-column-end: 3; 
        }
        .contact-info-wrapper{
            grid-row: 3; 
            grid-column-start: 1;
            grid-column-end: 3; 
            grid-template-areas: "firstname firstname"
            "lastname lastname"
            "purpose purpose"
            "email email"
            "message message"
            "none submit";
            padding-bottom: 70px; 

        }
    }
    
}