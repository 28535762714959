$breakpoint-moblie: 850px; 
$social-img-size: 25px; 

.footer-wrapper{
  border-top: 1px solid black; 
  padding-top: 20px;
  padding-bottom: 20px;  
  bottom: 0; 

  .footer-em{
    text-transform: uppercase;
    background-color: black; 
    width: fit-content; 
    margin-top: 10px; 
    margin-left: auto; margin-right: auto; 
    margin-bottom: 10px; 
    padding: 7px 15px 10px 15px; 
    a{
      font-size: 12px; 
      color: white; 
      text-decoration: none;
    }
  }

  

  .footer-sociallinks{
      display: grid;
      grid-template-areas: "none fb tw ig"; 
      row-gap: 10px; 
      grid-template-columns: 1fr repeat(3, fit-content(200px)) 1fr;
      grid-template-rows: 1fr; 
      text-transform: uppercase;
      column-gap: 50px;

      .footer-ig{
        grid-area: ig; 
        img{
          width: $social-img-size; 
        }
      }
      .footer-fb{
        grid-area: fb; 
        img{
          width: $social-img-size; 
        }
      }
      .footer-tw{
        grid-area: tw; 
        img{
          width: $social-img-size; 
        }
      }
      .footer-em{
        grid-area: em; 
        align-self: center; 
      }
      .footer-em2{
        grid-area: em2; 
        align-self: center; 
        
      }
      padding-bottom: 10px; 
  }

  @media(max-width: $breakpoint-moblie){
    .footer-sociallinks{
      row-gap: 20px; 
      padding-bottom: 20px; 
    }
}

  .footer-accessibilitylinks{
    font-size: 12px; 
    display: grid;
    grid-template-columns: fit-content(100px) fit-content(100px);
    text-transform: uppercase;
    column-gap: 40px;
    margin-left: auto; margin-right: auto; 
    width: fit-content; 
}
}
