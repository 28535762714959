$input-background-color: rgb(224, 224, 224); 
$input-height: 40px; 
$input-width: 100%; 
$input-font-size: 16px; 
$input-padding: 7px 7px 7px 7px; 


.ce-wrapper{
    display: grid; 
    grid-template-columns: 100px auto;
    column-gap: 10px;

    h3{
        margin: 0; 
        font-size: 14px; 
        font-weight: 400;
        grid-column: 1;
        text-transform: uppercase;
    }
    .ce-input{
        grid-column: 2; 
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        select{
            color: black; 
            border-radius: 0px;
            box-sizing: border-box;
            padding: $input-padding; 
            outline: none; 
            font-size: $input-font-size;
            background-color: $input-background-color;
            border: transparent; 
            width: $input-width; 
            height: $input-height; 
        }
        select:hover{
            cursor: pointer; 
        }
        textarea{
            box-sizing: border-box;
            padding: $input-padding; 
            outline: none; 
            font-size: $input-font-size;
            background-color: $input-background-color;
            width: $input-width;
            height: $input-height; 
            border: transparent; 
            border-radius: 0;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            transition: ease-in-out .75s; 

        }
        textarea:focus{
            height: 150px; 
        }
        input{
            box-sizing: border-box;
            padding: $input-padding; 
            outline: none; 
            font-size: $input-font-size;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            background-color: $input-background-color;
            border: transparent; 
            border-radius: 0px;
            width: $input-width;
            height: $input-height; 
        }
    }
}