$breakpoint-mobile: 850px; 
$logo-width: 350px; 

.home-wrapper{
    width: 100%; 

    .home-hero-section{
        width: 100%; 
        height: max(100vh, 630px); 
        display: grid; 
        grid-template-columns: 100%; 
        grid-template-rows: 100vh; 


    


    .home-text-wrapper{
        z-index: 1; 
        grid-row: 1; 
        grid-column: 1; 
        font-size: 40px;
        font-weight: 900; 
        display: grid; 
        grid-template-columns: 1fr $logo-width 1fr; 
        grid-template-rows: 33% min-content min-content 1fr; 
        .home-header{
            img{
                width: $logo-width; 
            }
            grid-column: 2; 
            grid-row: 2;
        }
        a{
            justify-self: center;
            grid-column: 2; 
            width: min-content; 
            grid-row: 3; 
            text-decoration: none;
            color: black; 
            display: inline-block; 
            margin-top: 30px; 
            border: 1px solid black; 
            font-size: 18px; 
            font-weight: 400;
            padding: 20px 35px 20px 35px; 
            transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        a:hover{
            transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
            background: black; 
            color: white; 
            border-color: black; 
        }

        

    }
    @media (max-width: $breakpoint-mobile){
        .home-text-wrapper{
            grid-template-columns: 1fr auto 1fr;
        }
    }

}
}