.navbar-wrapper{
    z-index: 100; 
    background-color: white;
    position: fixed; 
    width: 100vw; 
    height : 50px; 
    display: grid; 
    grid-template-columns: auto auto fit-content(100px);
    grid-template-rows: 1fr 35px 1fr ; 

    

    .navbar-logo{
        grid-column: 1;
        grid-row: 2; 
        padding-left: 30px; 
    }

    .navbar-contact-btn{
        align-self: center; 
        grid-column: 3;
        grid-row: 2; 
        padding-right: 30px; 
    }

}

#showNavbar{
    transform: translate(0,0); 
    transition:linear 0.15s; 
}

#noShowNavbar{
    transition:linear 0.15s; 
    transform: translate(0, -60px); 
}